import React from 'react';
import {Link} from 'gatsby';

import classes from './news-list-item.module.css';

interface NewsListItemProps {
  id: string;
  title: string;
  date: string;
}

const NewsListItem: React.FC<NewsListItemProps> = ({id, title, date}) => {
  return (
    <Link to={`/news/${id}`} className={classes.news_link}>
      <div className={classes.news_date}>{date}</div>
      <h2 className={classes.news_title}>{title}</h2>
    </Link>
  );
};

export default NewsListItem;
