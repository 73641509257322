import React, {useRef} from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Slider from 'react-slick';
import {GatsbyImage, IGatsbyImageData, getImage} from 'gatsby-plugin-image';
import ReactHtmlParser from 'react-html-parser';

import Container from './container';
import classes from './news-slider.module.css';

type NewsQueryResult = {
  allNewsYaml: {
    edges: {
      node: {
        id: string;
        title: string;
        description: string;
        image: IGatsbyImageData;
      };
    }[];
  };
};

const query = graphql`
  query {
    allNewsYaml(limit: 6) {
      edges {
        node {
          id
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 585, height: 585, quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

const NewsSlider: React.FC = () => {
  const sliderRef = useRef<Slider>(null);

  const data = useStaticQuery<NewsQueryResult>(query);
  const news = data.allNewsYaml.edges.map(({node}) => node);

  return (
    <Container>
      <div className={classes.root}>
        <Slider
          ref={sliderRef}
          arrows={false}
          slidesToShow={3}
          responsive={[
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {news.map(({id, title, description, image}) => (
            <Link to={`/news/${id}`} key={title} className={classes.news}>
              <div className={classes.news_img_container}>
                <GatsbyImage image={getImage(image)!} alt={title} className={classes.news_img} />
              </div>
              <h2 className={classes.news_title}>{title}</h2>
              <div className={classes.news_text}>{ReactHtmlParser(description)}</div>
              <span className={classes.news_link}>Детальніше...</span>
            </Link>
          ))}
        </Slider>

        <div className={classes.slider_btns}>
          <button
            className={classes.prev_btn}
            onClick={() => sliderRef.current!.slickPrev()}
            aria-label="Попередня новина"
          />
          <button
            className={classes.next_btn}
            onClick={() => sliderRef.current!.slickNext()}
            aria-label="Наступна новина"
          />
        </div>
      </div>
    </Container>
  );
};

export default NewsSlider;
